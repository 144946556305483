<template>
  <div>
    <getsetData v-bind:flgValue="flg"></getsetData>
  </div>
</template>

<script>
import getsetData from './bdcharts/getsetData'

export default {
  name: 'BloodDonation2',
  components: {
    getsetData,
  },
  data () {
    return {
      flg: ''
    }
  },
  created () {
    this.flg = 'flg3'
  }
}

</script>
